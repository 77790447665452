import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "financial-advisor-web-design.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)
export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div style={{ padding: "60px 0px 60px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center">
          <Grid
            item
            md={5}
            sm={10}
            lg={5}
            xs={10}
            style={{ paddingBottom: "60px" }}
          >
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="financial-advisor-web-design"
            />
          </Grid>
          <Grid item lg={4} md={10} sm={11} xs={11}>
            <h3 className={classes.title}>Launch with Confidence</h3>
            <h6 className={classes.description}>
              The Importance of Web Design
            </h6>
            <p className={classes.description}>
              Along with a quality SEO strategy, web design is an essential
              component of online success. Without one or the other, your
              digital marketing investment can easily fail to provide the wanted
              results.
            </p>
            <p className={classes.description}>
              Statistics show how the three top pages on Google’s results have
              the highest chances for clicks. However, statistics also show that
              poorly designed websites fail to convert visitors into paying
              clients. If your website is not optimized for mobile users or if
              it lacks visual appeal, your visitors will quickly turn their
              attention to your competition.
            </p>
            <p className={classes.description}>
              SEO & web design are a must if you want to portray professionalism
              and catch the attention of online consumers. Choose the
              experienced professionals at Atlas Agency to provide you with
              modern financial website design and a coherent digital marketing
              strategy.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
