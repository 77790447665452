import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import styles from "../jss/landing-webdesign.js"
import classNames from "classnames"

// core components
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"
import Grid from "@material-ui/core/Grid"

import HeaderLinks from "../components/Header/HeaderLinks.jsx"
import Parallax from "../components/Parallax/ParallaxFinance.jsx"

import Intro from "../pages-sections/FinancialAdvisorDesign/Intro.jsx"
import Info from "../pages-sections/FinancialAdvisorDesign/Info.jsx"
import Banner from "../pages-sections/FinancialAdvisorDesign/Banner.jsx"
import Contact from "../pages-sections/FinancialAdvisorDesign/Contact.jsx"
import Marketing from "../pages-sections/FinancialAdvisorDesign/Marketing.jsx"
import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"

const useStyles = makeStyles(styles)

export default function LandingPage(props) {
  const classes = useStyles()
  const { ...rest } = props
  return (
    <div>
      <LogoJsonLd
        logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
        url="https://atlasagency.ca"
      />
      <GatsbySeo
        title="Financial Advisor Web Design | Toronto | Atlas Agency"
        description="Financial advisor web design is our specialty, we have extensive experience working with financial advisors. Call us today to get started on a website design for financial advisors."
        canonical="https://atlasagency.ca/financial-advisor-web-design/"
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
          url: "https://atlasagency.ca",
          site_name: "Atlas Agency | Web Design Toronto",
          images: [
            {
              url:
                "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
              width: 800,
              height: 450,
              alt: "Web Design Toronto",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@AtlasAgencyCA",
        }}
      />
      <Header
        color="transparent"
        brand="Atlas Agency"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 60,
          color: "dark",
        }}
        {...rest}
      />

      <Parallax filter className={classes.parallax} responsive>
        <div className={classes.container}>
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <h1 className={classes.title}> Financial Advisor Web Design</h1>
            </Grid>
          </Grid>
        </div>
      </Parallax>

      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ overflowX: "hidden" }}
      >
        <div className={classes.container}>
          <Intro />
        </div>
        <div>
          <Marketing />
        </div>
        <div>
          <Info />
          <Banner />
        </div>
        <div className={classes.container}>
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  )
}
