import React, { useState, useEffect } from "react"
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/parallaxStyle.js"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

// core components

const useStyles = makeStyles(styles)

const getImages = graphql`
  {
    file(relativePath: { eq: "financial-advisor-web-design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export default function Parallax(props) {
  const data = useStaticQuery(getImages)
  const { children } = props
  const classes = useStyles()
  return (
    <BackgroundImage
      fluid={data.file.childImageSharp.fluid}
      alt="financial-advisor-web-design"
      className={classes.parallax}
      style={{
        backgroundPosition: "bottom center",
        backgroundSize: "cover",
      }}
    >
      <div className={classes.blackOverlay}>{children}</div>
    </BackgroundImage>
  )
}
