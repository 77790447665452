import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import styles from "../../jss/intro.js"

import DevicesIcon from "@material-ui/icons/Devices"
import CreateIcon from "@material-ui/icons/Create"
import CloudDoneIcon from "@material-ui/icons/CloudDone"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Grid container justify="center">
        <Grid item xs={10} sm={12} md={8} lg={8} align="center">
          <h2 className={classes.title}>Financial Advisor Website Design</h2>
        </Grid>
        <Grid
          item
          xs={11}
          sm={11}
          md={8}
          lg={11}
          style={{ padding: "20px 0px 0px 0px" }}
        >
          <p className={classes.description}>
            The team at Atlas Agency is aware of the importance of quality web
            design. Our methods are proven, reliable, and effective. When you
            work with us, navigating the online world is easy. Our track record
            shows our capabilities and how we constantly use them to help
            companies around Canada. Our financial services website design
            package include:
          </p>
        </Grid>
      </Grid>
      <div style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={5}>
          <Grid item xs={11} sm={10} md={4} lg={4}>
            <InfoArea
              title="Custom Made Websites"
              description="We provide resources that will make your website stand out from the competition. The financial industry is competitive and requires businesses to get creative to beat competition. A custom made website tailored to your target market is crucial to getting more business through your doors."
              icon={CreateIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Hosting"
              description="We ensure that your website runs 24/7 and is available to online visitors at all times. We also ensure your website is encrypted and safe to use for your clients. Security is always our number one priority when creating financial websites."
              icon={CloudDoneIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Mobile Optimization"
              description="If your financial services web design is not optimized for mobile usage, you can lose potential clients. Atlas Agency creates websites that are user-friendly for mobile, tablet, and desktop usage. Use our services to reach more potential clients. "
              icon={DevicesIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
