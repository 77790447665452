import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"

import styles from "../../jss/intro.js"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
  sectionDiv: {
    padding: "0px 0px 60px 0px",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" className={classes.sectionDiv}>
        <Grid item xs={11} sm={11} md={8} lg={8}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            From metadata and security to branding and graphics, we take care of
            every single detail. Not only do we help you build and maintain your
            website, but we also offer hosting with reputable platforms. Our
            custom made websites enable us to provide a safe, user-friendly and
            cutting-edge design to clients. Use our tools to make the most out
            of your financial business. Feel free to contact us for more
            information. We’d love to contribute to your financial advisor web
            design process.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
